import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';

import { IndividualOrderPopupModal } from '@/components/individual-order';
import { AdditionalCheckoutProductsModal } from '@/components/ui/modals/additional-checkout-products-modal/AdditionalCheckoutProductsModal';
import DeliveryLimitsModal from '@/components/ui/modals/delivery-limits-modal/DeliveryLimitsModal';
import LoginModal from '@/components/ui/modals/login-modal/LoginModal';
import MobileApplicationQrModal from '@/components/ui/modals/mobile-application-qr-modal/MobileApplicationQrModal';
import OneClickModal from '@/components/ui/modals/one-click-modal/OneClickModal';
import PartsPaymentModal from '@/components/ui/modals/parts-payment-modal/PartsPaymentModal';
import SupportModal from '@/components/ui/modals/support-modal/SupportModal';

export const ModalContainer: FC = () => {
  return (
    <>
      <LoginModal />
      <OneClickModal />
      <IndividualOrderPopupModal />
      <SupportModal />
      <MobileApplicationQrModal />
      <PartsPaymentModal />
      <DeliveryLimitsModal />
      <AdditionalCheckoutProductsModal />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
